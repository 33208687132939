<template>
  <el-form ref="accountFormRef" :inline="true" :model="accountForm">
    <el-row justify="space-between">
      <el-col :span="20">
        <el-form-item label="广告账户ID" prop="adIdValue">
          <el-input placeholder="请输入广告账户ID" size="small" clearable v-model="accountForm.adIdValue"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 14px">
          <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
          <el-button @click="resetFormBtn" size="small">重置</el-button>
        </el-form-item>
      </el-col>
      <el-col :span="4" style="text-align: right">
        <el-button type="text" class="LinkText" @click="downLoadData">下载</el-button>
      </el-col>
    </el-row>
  </el-form>
  <el-table
    :data="YLCheckList"
    :row-key="getAccountId"
    style="width: 100%"
    @selection-change="handleSelectionChange"
    :header-cell-style="{
      background: 'var(--el-table-row-hover-background-color)',
    }"
  >
    <el-table-column type="selection" :reserve-selection="true" :selectable="blockedRow" width="55" />
    <el-table-column property="account_id" label="广告账户ID" />
    <el-table-column property="ad_id" label="广告ID" />
    <el-table-column label="广告" width="110">
      <template #default="{ row }">
        <el-image
          style="width: 50px; height: 50px"
          :src="row.illegal_img_path"
          :preview-src-list="[row.illegal_img_path]"
        >
        </el-image>
      </template>
    </el-table-column>
    <el-table-column property="reason" label="违规原因" />
    <el-table-column property="desc_type" label="处理措施" />
    <el-table-column property="comment" label="备注" />
    <el-table-column property="date" label="时间" width="110" />
    <el-table-column label="复审状态" width="110">
      <template #default="{ row }">
        <el-tag v-if="row.type == 0">待提交</el-tag>
        <el-tag type="warning" v-else-if="row.type == 1">审核中</el-tag>
        <el-tag type="danger" v-else-if="row.type == 2">被拒绝</el-tag>
        <el-tag type="danger" v-else-if="row.type == 4">已失效</el-tag>
        <el-tag type="success" v-else>已通过</el-tag>
      </template>
    </el-table-column>
    <template #empty>
      <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
    </template>
  </el-table>
  <el-row>
    <el-col :span="12" style="padding-top: 15px">
      <el-button size="mini" @click="submitRecheck" :type="SelectNumber == 0 ? '' : 'primary'">提交复审</el-button>
      <span class="Text" style="margin-left: 15px">已选择 {{ SelectNumber }} 个</span>
    </el-col>
    <el-col :span="12" style="text-align: right">
      <!-- 分页器 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </el-col>
  </el-row>
</template>

<script>
import { ElLoading } from 'element-plus'

export default {
  name: 'ADCheck',
  data() {
    return {
      userInfo: '',
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5,
      },
      accountForm: {
        adIdValue: '',
      },
      total: 0,
      // YL检测列表
      YLCheckList: [],
      SelectNumber: 0,
      newArrayIds: [],
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getADCheck()
  },
  methods: {
    // 查询按钮
    queryFormBtn() {
      this.getADCheck()
    },
    // 重置按钮
    resetFormBtn() {
      this.$refs.accountFormRef.resetFields()
      this.getADCheck()
    },
    // table需设置row-key
    getAccountId(row) {
      return row.g_id
    },
    async getADCheck() {
      const { data: res } = await this.$http.post('cloudfb/violation_local_list', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        ad_id: this.accountForm.adIdValue,
      })
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.YLCheckList = res.data
      this.total = res.total
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getADCheck()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getADCheck()
    },
    handleSelectionChange(selection) {
      this.SelectNumber = selection.length
      const newArrIds = []
      selection.forEach((item) => {
        newArrIds.push(item.g_id)
      })
      this.newArrayIds = newArrIds
    },
    downLoadData() {
      if (this.YLCheckList == '') {
        return this.$message({
          showClose: true,
          message: '暂无数据',
          type: 'warning',
        })
      }
      //将数组转换为以逗号隔开的字符串
      const id = this.newArrayIds.join(',')

      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...',
      })
      this.$http({
        // 用axios发送post请求
        method: 'post',
        url: 'cloudfb/violation_export', // 请求地址
        data: {
          id: id,
          user_id: this.userInfo.id,
          type: 1,
        },
        responseType: 'blob', // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          // 处理返回的文件流
          const content = res.data
          const blob = new Blob([content])
          const fileName = '违规广告审核.xls'
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
        .finally(() => {
          loading.close()
        })
    },
    blockedRow(row, index) {
      if (row.type > 0 || row.date == '今天' || row.date == '昨天') {
        return true
      } else {
        return false
      }
    },
    async submitRecheck() {
      if (this.SelectNumber == 0) {
        return this.$message({
          showClose: true,
          message: '请选择广告账户',
          type: 'warning',
        })
      }

      const confirmResult = await this.$confirm(
        '确定已按照处理措施整改好了吗？提交复审仅有一次机会，若被驳回，则仍会按照正常规则暂停/删除广告',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)

      if (confirmResult !== 'confirm') {
        return
      } else {
        // 发起复审的请求
        const { data: res } = await this.$http.post('cloudfb/violation_check', {
          ids: this.newArrayIds,
        })

        if (res.code == 300) {
          this.$router.push('/login')
        }
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        window.location.reload()
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
