<template>
  <el-form ref="arefuseFormRef" :inline="true" :model="accountForm">
    <el-row justify="space-between">
      <el-col :span="20">
        <el-form-item label="营业执照" prop="company">
          <el-input placeholder="请输入营业执照" size="small" clearable v-model="accountForm.company"></el-input>
        </el-form-item>
        <el-form-item label="广告账户ID" prop="adIdValue">
          <el-input placeholder="请输入广告账户ID" size="small" clearable v-model="accountForm.adIdValue"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 14px;">
          <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
          <el-button @click="resetFormBtn" size="small">重置</el-button>
        </el-form-item>
      </el-col>

      <el-col :span="4" style="text-align: right;">
        <!-- <el-button type="text" @click="downLoadData">下载</el-button> -->
        <el-link @click="downLoadData">
          <span class="LinkText">下载</span>
        </el-link>
      </el-col>
    </el-row>
  </el-form>

  <el-alert title="广告拒登申诉指南" description="" type="info" show-icon>
    <template #default>
      出现拒登广告，不要删除，不要删除，不要删除！<br />
      不可以更改广告，不可以更改广告，不可以更改广告！ 请立即申诉，申诉，申诉！<br />
      最后，真的申诉不回来，请严格删除违规广告， 同类型也不要再投放！
    </template>
  </el-alert>

  <el-table :data="refuseList" :row-key="getAccountId" :header-cell-style="{
    background: 'var(--el-table-row-hover-background-color)'
  }" style="width: 100%" @selection-change="handleSelectionChange">
    <el-table-column type="selection" :reserve-selection="true" width="55" />
    <el-table-column prop="business_license" label="营业执照" />
    <el-table-column prop="name" label="账户名称" />
    <el-table-column prop="account_id" label="广告账户ID" />
    <el-table-column prop="ad_id" label="广告ID" />
    <el-table-column prop="ad_total_revenue_28d" label="违规消耗" />
    <el-table-column prop="policy" label="广告违规原因" />
    <template #empty>
      <img src="@/assets/empty.gif" alt="" style="width:400px;height:400px" />
    </template>
  </el-table>
  <el-row>
    <el-col>
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[5, 10]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
        :total="total" background>
      </el-pagination>
    </el-col>
  </el-row>
</template>

<script>
import { ElLoading } from 'element-plus'
export default {
  name: 'ADRefuse',
  data() {
    return {
      userInfo: '',
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5
      },
      accountForm: {
        company: '',
        adIdValue: ''
      },
      total: 0,
      // 拒登广告列表
      refuseList: [],
      newArrayIds: []
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getRefuseList()
  },
  methods: {
    // 查询按钮
    queryFormBtn() {
      this.getRefuseList()
    },
    // 重置按钮
    resetFormBtn() {
      this.$refs.arefuseFormRef.resetFields()
      this.getRefuseList()
    },
    // table需设置row-key
    getAccountId(account) {
      return account.ad_id
    },
    async getRefuseList() {
      const { data: res } = await this.$http.post('cloudfb/violation_larr_list', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        ad_id: this.accountForm.adIdValue,
        company: this.accountForm.company
      })
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.refuseList = res.data
      this.total = res.total
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getRefuseList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getRefuseList()
    },
    handleSelectionChange(selection) {
      this.SelectNumber = selection.length

      const newArrIds = []
      selection.forEach(item => {
        newArrIds.push(item.ad_id)
      })
      this.newArrayIds = newArrIds
    },
    downLoadData() {
      if (this.refuseList == '') {
        return this.$message({
          showClose: true,
          message: '暂无数据',
          type: 'warning'
        })
      }
      //将数组转换为以逗号隔开的字符串
      const id = this.newArrayIds.join(',')

      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...'
      })

      this.$http({
        // 用axios发送post请求
        method: 'post',
        url: 'cloudfb/violation_export', // 请求地址
        data: {
          id: id,
          user_id: this.userInfo.id,
          type: 2
        },
        responseType: 'blob' // 表明返回服务器返回的数据类型
      })
        .then(res => {
          // 处理返回的文件流
          const content = res.data
          const blob = new Blob([content])
          const fileName = '拒登广告.xls'
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
        .finally(() => {
          loading.close()
        })
    }
  }
}
</script>

<style lang="less" scoped>
.el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

:deep(.el-alert .el-alert__description) {
  font-size: 16px;
  color: #848d92 !important;
}

:deep(.el-alert__icon) {
  background-color: #e6f7ff !important;
  color: #2b82ff !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}
</style>
