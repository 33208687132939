<template>
  <div>
    <el-row :gutter="20" class="firsrRow" style="margin-bottom: 20px;">
      <el-col :span="16">
        <AccountState :accountStateItem="accountStateItem"></AccountState>
      </el-col>

      <el-col :span="8">
        <!-- 余额组件 -->
        <usable-balance></usable-balance>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-card>
          <el-tabs v-model="activeName">
            <!-- 广告审核-YL每天检测列表 -->
            <el-tab-pane label="广告审核-YL每天检测" name="YL">
              <template #label>
                <span> 广告审核-每天检测( {{ AuditNum }} ) </span>
                <el-tooltip class="item" effect="light" content="" placement="top">
                  <template #content>
                    MMCloud根据Facebook的<br />广告政策，审查出来的有可能被Facebook<br />抓取的广告。请提前进行整改，以及避免<br />出现同类型违规
                  </template>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </template>
              <ADCheck />
            </el-tab-pane>

            <!-- 拒登广告-FB近28天抓取列表 -->
            <el-tab-pane label="拒登广告-FB近28天抓取" name="blocked">
              <template #label>
                <span> 拒登广告-FB近28天抓取( {{ ErrNum }} ) </span>
                <el-tooltip class="item" effect="light" content="" placement="top">
                  <template #content>
                    最近28天被Facebook判定违规的广告，<br />请前往广告管理后台进行申诉，同时请<br />避免出现同类型违规。
                  </template>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </template>
              <ADRefuse />
            </el-tab-pane>
          </el-tabs>

          <!-- <router-link to="subMsg"><span class="subReport">订阅报告</span></router-link> -->
          <!-- <el-button
            type="text"
            id="subReport"
            class="subReport"
            @click="subReport"
            v-show="activeName == 'YL' && is_type == 0"
          >
            <span v-if="this.sub_type == 0">订阅报告</span>
            <span v-else class="successColor">新增邮箱（已订阅）</span>
          </el-button> -->
        </el-card>
      </el-col>
    </el-row>
  </div>

  <!-- 订阅报告时验证邮箱是否绑定 -->
  <el-dialog v-model="confirmEmailFormDialogVisible" @close="confirmEmailFormClose" title="订阅报告" width="600px"
    custom-class="subDialog">
    <p v-if="this.sub_type == 0">
      是否确认订阅广告审核报告，订阅后每天下午五点前会发送报告到您添加的邮箱中，如需订阅，请在下方添加邮箱并订阅。
    </p>
    <p v-else>
      您已订阅广告审核报告，每天下午五点前会发送报告到您添加的邮箱中，如需多个邮箱接收，请在下方新增。
    </p>
    <!-- :rules="confirmEmailFormRules" -->
    <el-form ref="confirmEmailFormRef" :model="confirmEmailForm">
      <!-- <el-form-item prop="email">
        <el-input v-model="confirmEmailForm.email" size="small" placeholder="请输入邮箱号" clearable></el-input>
      </el-form-item> -->
      <el-form-item prop="emailValue">
        <el-select v-model="confirmEmailForm.emailValue" multiple filterable allow-create default-first-option
          :reserve-keyword="false" placeholder="请输入邮箱号码" style="width: 100%;">
          <!-- <el-option v-for="item in emailOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option> -->
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" type="primary" @click="confirmEmailBtn">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import AccountState from '../AccountState.vue'
import UsableBalance from '../UsableBalance.vue'
import ADCheck from './ADCheck.vue'
import ADRefuse from './ADRefuse.vue'
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'

export default {
  components: { AccountState, UsableBalance, ADCheck, ADRefuse },
  name: 'UserViolationList',
  data() {
    return {
      userInfo: '',
      hideEmail: '',
      accountStateItem: {},
      // YL审核
      AuditNum: '',
      //拒登
      ErrNum: '',
      activeName: 'YL',
      is_sub: '',
      is_type: '',
      // 绑定邮箱获取验证码倒计时默认时间
      countDownTime: 0,
      // 绑定邮箱定时器
      bindEmailClock: undefined,
      confirmEmailFormDialogVisible: false,
      confirmEmailForm: {
        emailValue: [],
        code: ''
      },
      // emailValue: [],
      emailOptions: [],
      sub_type: ''
    }
  },
  computed: {
    rightEmail() {
      return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.confirmEmailForm.email)
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getViolationInfo()
    this.emailNumShow()
  },
  methods: {
    //隐藏邮箱号
    emailNumShow() {
      if (!this.userInfo.email) {
        return
      } else {
        let number = this.userInfo.email
        let memail = number.substring(0, 3) + '***' + number.substring(6)
        this.hideEmail = memail
      }
    },
    async getViolationInfo() {
      const { data: res } = await this.$http.post('cloudfb/violation_data', {
        token: this.userInfo.token
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.accountStateItem = res.data.accountStateItem
      // 广告审核-YL每天检测
      this.AuditNum = res.data.local_num
      // 拒登广告-FB近28天抓取
      this.ErrNum = res.data.larr_num
      this.is_type = res.data.type
      this.confirmEmailForm.emailValue = res.data.sub
      this.sub_type = res.data.sub_type
      this.driver()
    },
    // 订阅报告
    // async subReport() {
    //   if (this.userInfo.is_email == 1) {
    //     const confirmResult = await this.$confirm('', '订阅报告', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       dangerouslyUseHTMLString: true,
    //       type: 'warning',
    //       message: `
    //       是否确认订阅广告审核报告，订阅后每天下午五点前会发送报告到您绑定的${this.hideEmail}邮箱中
    //       `
    //     }).catch(err => err)

    //     if (confirmResult !== 'confirm') {
    //       return
    //     } else {
    //       const { data: res } = await this.$http.post('sub', {
    //         token: this.userInfo.token
    //       })
    //       if (res.code == 300) {
    //         this.$router.push('/login')
    //       }
    //       if (res.code == 200) this.$message.success('订阅成功')
    //       window.location.reload()
    //     }
    //   } else {
    //     this.confirmEmailFormDialogVisible = true
    //   }
    // },
    // 订阅报告(新)
    async subReport() {
      this.confirmEmailFormDialogVisible = true
    },
    // 确认绑定邮箱并订阅
    confirmEmailBtn() {
      this.$refs.confirmEmailFormRef.validate(async valid => {
        if (!valid) return
        var emailValue = this.confirmEmailForm.emailValue.join(',')
        const { data: res } = await this.$http.post('cloudfb/sub', {
          email: emailValue,
          type: 'violation_email',
        })
        if (res.code == 100) return this.$message.error(`${res.msg}`)
        if (res.code == 300) {
          this.$router.push('/login')
        }
        this.$bus.emit('getTask')
        this.$message.success(`${res.msg}`)
        this.confirmEmailFormDialogVisible = false
        window.location.reload()
      })
    },
    // 设置邮箱对话框关闭事件
    confirmEmailFormClose() {
      clearInterval(this.bindEmailClock)
      this.countDownTime = 0
    },
    driver() {
      var taskRoport = window.localStorage.getItem('taskRoport')
      if (this.sub_type == 0 && taskRoport == 1) {
        const driver = new Driver({
          opacity: 0.1
        })
        driver.highlight({
          element: '#subReport',
          popover: {
            title: '订阅广审提醒',
            description: '点击按钮设置订阅邮箱',
            closeBtnText: '确定',
            position: 'left'
          }
        })
        window.localStorage.setItem('taskRoport', 0)
      }
    }
  }
}
</script>

<style lang="less" scoped>
i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}

:deep(.el-tabs__header) {
  margin-bottom: 30px;
}

:deep(.el-tabs__item) {
  font-size: 16px !important;
}

.subReport {
  position: absolute;
  top: 25px;
  right: 20px;
  font-size: 14px;
  color: #2b82ff;
}
</style>
